import React, { useState } from "react";
import DropDown from "./DropDown";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "aliceblue",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: "Helvetica",
    fontWeight: "Bold",
    borderRadius: "15px",
    border: "1px solid lightgrey",
    padding: "10px",
  },
}))(Tooltip);

const AccountIcon = (props) => {
  const [drop, setDrop] = useState(false);

  return (
    <div className="rr-btn">
      <div className="rr-icon">
        <LightTooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title="Notifications"
        >
          <button className="btn-icon btn-icon1">
            <svg
              width="18"
              height="18"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4 7.8C13.6 7.1 13 6.7 13 6V5C13 2.2 10.8 0 8 0C5.2 0 3 2.2 3 5V6C3 6.7 2.4 7.1 1.6 7.8C0.8 8.4 0 9 0 10C0 12.8 6.1 13 8 13C9.9 13 16 12.8 16 10C16 9 15.2 8.4 14.4 7.8Z"
                fill="rgb(39, 117, 219)"
              ></path>
              <path
                d="M10.9 13.8999C9.79998 13.9999 8.89998 13.9999 7.99998 13.9999C7.09998 13.9999 6.19998 13.9999 5.09998 13.8999C5.49998 15.0999 6.59998 15.9999 7.99998 15.9999C9.39998 15.9999 10.5 15.0999 10.9 13.8999Z"
                fill="rgb(39, 117, 219)"
              ></path>
            </svg>
          </button>
        </LightTooltip>

        <LightTooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={props.accAddress.substring(0, 5)}
        >
          <button
            onClick={() => setDrop(!drop)}
            className="btn-icon btn-icon1"
            style={{ marginLeft: "15px" }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 10C9.65685 10 11 8.65685 11 7C11 5.34315 9.65685 4 8 4C6.34315 4 5 5.34315 5 7C5 8.65685 6.34315 10 8 10Z"
                fill="rgb(39, 117, 219)"
              ></path>
              <path
                d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12 12.4C11.4 11.7 10.6 11 9 11H7C5.4 11 4.6 11.7 4 12.4C2.8 11.3 2 9.8 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 9.8 13.2 11.3 12 12.4Z"
                fill="rgb(39, 117, 219)"
              ></path>
            </svg>
          </button>
        </LightTooltip>
        {drop ? <DropDown accAddress={props.accAddress} /> : null}
      </div>
    </div>
  );
};

export default AccountIcon;
