import React from "react";
import { Form, Input, InputNumber } from "antd";
import Avatar from "./Avatar";

const { TextArea } = Input;

const MintNFT = () => {
  return (
    <div className="nft-create-card">
      <form className="ant-form ant-form-horizontal">
        <div className="nft-img-upload-cover">
          <div style={{ paddingTop: 10}}>
            <Avatar />
          </div>
        </div>
        <div className="nft-create-card-bottom">
          <div className="ant-col">
            <Form>
              <div class="input_1">
                <Input placeholder="NFT Name" required />
              </div>
              <div class="input_1">
                <TextArea rows={4} placeholder="NFT Description" />
              </div>
              <div class="input_2">
                <InputNumber
                  style={{ width: 200, border: "none" }}
                  defaultValue=""
                  min="0"
                  max="10"
                  step="0.00000000000001"
                  placeholder="NFT Price"
                  stringMode
                />{" "}
                <span>BNB</span>
              </div>
            </Form>
            <div
              className="ant-row ant-row-space-between create-bottom-row"
              style={{ rowGap: "0px", padding: "5px" }}
            >
              <div>Service Fee</div>
              <div>2.5%</div>
            </div>
            <div
              className="ant-row ant-row-space-between create-bottom-row"
              style={{ rowGap: "0px" }}
            >
              <div style={{ padding: "5px" }}>You will receive</div>
              <div></div>
              <button
                type="submit"
                class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
              >
                <span className="nft-card-buy-button-text">Create</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MintNFT;
