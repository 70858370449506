import React, { Component } from "react";

export class SocialProfiles extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="form-container">
        <h1 className="mb-5">Profile Info</h1>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={inputChange("name")}
            value={values.name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            className="form-control"
            name="email"
            onChange={inputChange("email")}
            value={values.email}
          />
        </div>
        {/* <div className="mb-3">
          <Form.File id="formcheck-api-regular">
            <Form.File.Label>Choose Image for Bill Board</Form.File.Label>
            <Form.File.Input />
          </Form.File>
        </div> */}

        <br />

        <div className="row">
          <div className="col-6">
            <div className="button-left" onClick={this.back}>
              <i className="fa fa-arrow-left"></i>
              <div></div>
              Previous
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="button" onClick={this.continue}>
              Next
              <div></div>
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialProfiles;
