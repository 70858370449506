import React, { useState, useContext,useEffect } from "react";
import "./Stake.css";
import Button from "react-bootstrap/Button";
import { Web3Context } from "../Context/Web3context";
import FusiLogo from "../images/fusii-logo.png";
import PancakeLogo from "../images/pancakeswap.png";
import {FusiiToken,Busd,CakeV0,CakeV2,StakeV2} from "../Contract/constant";
import {Ierc20,CakePair} from "../Contract/abi/abi";

const Stake = () => {

  const state = useContext(Web3Context);
  //const [poolData,setPoolData] = useState({tvl:0,apy:0,endTime:'',fusiiValue:0,lpValue:0});
  const [tvl, setTVL] = useState(0);
  const [apy,setAPY] = useState(0);
  const [fusiiUSD,setfusiiUSD] = useState(0);
  const [lpUSD,setLpUSD]=useState(0);
  const [endTime,setEndTime] = useState(''); 
  const [isApproved,setApprove]=useState(false);
  const [availLP,setAvailLP]=useState(0);
  const [stakedLP,setStakeLP]= useState(0);
  const [unclaimed,setUnclaimed]=useState(0);
  const [toggle, setToggle] = useState(false);
  const [inputValue, setInputValue] = useState();

  /**
   * (totalTokensPerDay*365*USDPrice*100)/ ((total LP usd value / Total LPSupply )*StakeLP)
   * apy=fusiiPerDay*365*fusiiValue*100/(lpValue*tvl);
   */
  const getFUSIIPrice=(tvl)=>{
    if(state !== undefined){
    let  pricePerFusii,TotalLPValue;
    const fusii = state.fusii;
    const busd =  new state.web3.eth.Contract(CakePair,Busd);
    const Cake = new state.web3.eth.Contract(CakePair,CakeV2);
    Cake.methods.token0().call({from:state.address}).then((r)=>{
      Cake.methods.getReserves().call({from:state.address}).then((reserves)=>{
       console.log(typeof reserves._reserve0,reserves);
       let r0 = parseFloat(reserves._reserve0);
       let r1 = parseFloat(reserves._reserve1);
       
      if(r === FusiiToken){
        pricePerFusii = r1/r0;
       setfusiiUSD(pricePerFusii);
      }else{
        pricePerFusii = r0/r1;
        setfusiiUSD(pricePerFusii); 
      }
      console.log(r === FusiiToken,r,FusiiToken,pricePerFusii);
      /**Get Price per LP , Total Value in USD/Total supply*/

      fusii.methods.balanceOf(CakeV2).call({from:state.address}).then((e)=>{
        let lpFusii = parseFloat(state.web3.utils.fromWei(e.toString(), "ether"));
        busd.methods.balanceOf(CakeV2).call({from:state.address}).then((e)=>{
        let lpBusd = parseFloat(state.web3.utils.fromWei(e.toString(), "ether"));
        /**Multiple lpFussi * prcePerFusii */


        Cake.methods.totalSupply().call({from:state.address}).then((e)=>{
          let tSpply =  parseFloat(state.web3.utils.fromWei(e.toString(), "ether"));
          TotalLPValue = (lpFusii*pricePerFusii)+lpBusd;
          let valuePerLP = TotalLPValue/tSpply;
          console.log(TotalLPValue,lpFusii,pricePerFusii,lpBusd);
          
          let _apy = (300*365*pricePerFusii*100)/(valuePerLP*tvl);
          //let _apy = (18000*365*pricePerFusii)/(valuePerLP*tvl);
          setAPY(_apy);
          setLpUSD(valuePerLP);

        })

        })
      })

      })

    })
    Cake.methods.balanceOf(state.address).call({from:state.address}).then((r)=>{
      console.log("Available LP",state.web3.utils.fromWei(r.toString(), "ether"));
      setAvailLP(state.web3.utils.fromWei(r.toString(), "ether"));
    })
    }
  }
  const getAPY = (stk) => {
    let totalTokensPerDay = 300;
  };
  const getTVL = (stk)=>{
    stk.methods.totalSupply().call({from:state.address}).then((r)=>{
      let _tvl = state.web3.utils.fromWei(r.toString(), "ether");
      _tvl = parseFloat(_tvl).toFixed(3);
      
      //console.log(pd);
      setTVL(_tvl);
      getEndTime(stk);
      getFUSIIPrice(_tvl);
    })
  }
  const getEndTime = (stk)=>{
    //periodFinish
    stk.methods.periodFinish().call({from:state.address}).then((r)=>{
      //let _tvl = state.web3.utils.fromWei(r.toString(), "ether");
      //_tvl = parseFloat(_tvl).toFixed(3);
      var utcSeconds = r;
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);
      setEndTime(d.toDateString());
      
    })
  }
  const _stakedLP =(stk)=>{
    stk.methods.balanceOf(state.address).call({from:state.address}).then((r)=>{
      let stkedLP = state.web3.utils.fromWei(r.toString(), "ether");
      //console.log("Staked",state.web3.utils.fromWei(r.toString(), "ether"));
      setStakeLP(stkedLP);
      if(parseFloat(stkedLP) > 0 )
        setApprove(true);
    });
    _unclaimed(stk);

  }
  const _unclaimed = (stk)=>{
    stk.methods.earned(state.address).call({from:state.address}).then((r)=>{
      console.log("Staked",state.web3.utils.fromWei(r.toString(), "ether"));
      setUnclaimed(state.web3.utils.fromWei(r.toString(), "ether"));
    })
  }
  const getReward =()=>{
    if(state !== undefined){
      const stk = state.stkv0;
      stk.methods.getReward().send({from:state.address}).then((r)=>{console.log(r);})
    }

  }
  const _stake =()=>{
    if(state !== undefined && inputValue > 0){
      let numStr = inputValue.toString();
      let ethValue = state.web3.utils.toWei(numStr, "ether");
      const stk = state.stkv0;
      stk.methods.stake(ethValue).send({from:state.address}).then((r)=>{console.log(r);
      _stakedLP(stk);
      getTVL(stk);
      })
    }
  }
  const _unstake = ()=>{
    if(state !== undefined && inputValue > 0){
      console.log(inputValue);
      let numStr = inputValue.toString();
      let ethValue = state.web3.utils.toWei(numStr, "ether");
      const stk = state.stkv0;
      stk.methods.unstake(ethValue).send({from:state.address}).then((r)=>{console.log(r);
      _stakedLP(stk);
      getTVL(stk);
      })
    }
  }
  
  const onInputValue = (e)=>{
    setInputValue(e.target.value);
  }
  const approveLPToken =()=>{
    if(state !== undefined){
      const Cake = new state.web3.eth.Contract(CakePair,CakeV2);
      let approveLimit = state.web3.utils.toWei("10000000", "ether");
      
      Cake.methods.approve(StakeV2,approveLimit).send({from:state.address}).then((r)=>{
        setApprove(true);

      })

    }

  }
  
  useEffect(() => {
  if(state !== undefined){
    const inv = state.inv;
    const fusii = state.fusii;
    const stk = state.stkv0;
    getTVL(stk);
    _stakedLP(stk);
    
  }},[state])
  return (
    <div>
      <div className="stake_container">
        <div className="row" style={{margin:"0 !important"}}>
          <div className="col">
          <h1>FUSII - LP Staking V2</h1>
          <h6>Lock your FUSII-BUSD CAKE LP tokens to earn extra APY!</h6>
          </div>
         
        </div>
        <div className="row" style={{margin:"0 !important"}}>

        <div className="col">
        <div className="row" style={{

                    fontSize: "18px",
                   border:"0.25px solid #1d4eda",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}>
              <div className="col">TVL</div>
              <div className="col">APY</div>
              <div className="col">End Time</div>
            </div>
            <div className="row" style={{
                   
                    fontSize: "18px",
                    color: "#1d4eda",
                    border:"0.25px solid #1d4eda",
                    textAlign: "right",
                    fontWeight: "bold",

                  }}>
            <div className="col">{tvl+" LP"} </div>
              <div className="col">{apy.toFixed(2)+"%"}</div>
              <div className="col">{endTime}</div>
            </div>
            </div>
        </div>
        <div className="create_c">
          <div className="cc">
            <h4>Create FUSII-BUSD CAKE LP tokens</h4>
            <p>Provide liquidity in Pancakeswap to get LP tokens.</p>
          </div>
          <div className="cc2">
            <a
              href="https://exchange.pancakeswap.finance//#/add/0x3A50d6daacc82f17A2434184fE904fC45542A734/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
              target="blank"
            >
              <Button type="submit">Add FUSII-BUSD Liquidity</Button>
            </a>
          </div>
        </div>
        {/*}
        <div className="rp">
          <div className="row">
            <div className="col-r">
              <h4>Reward Program</h4>
              <div className="row">
                <div className="col-left" style={{ fontSize: "18px" }}>
                  <p style={{ paddingBottom: "10px" }}>Total Value Locked</p>
                  <p style={{ paddingBottom: "10px" }}>
                    Annual Percentage Yield
                  </p>
                  <p style={{ paddingBottom: "10px" }}>Staking period</p>
                  <p style={{ paddingBottom: "10px" }}>End Time (UTC)</p>
                </div>

                <div
                  className="col-right"
                  
                >
                  <p style={{ paddingBottom: "10px" }}>0.000 LP</p>
                  <p style={{ paddingBottom: "10px" }}>0%</p>
                  <p style={{ paddingBottom: "10px" }}>90 days</p>
                  <p style={{ paddingBottom: "10px" }}>July 01 20201</p>
                </div>
              </div>
            </div>
            <div className="ll"></div>
            <div className="col-p">
              <h4>Pool Information</h4>
              <div className="row">
                <div className="col-left" style={{ fontSize: "18px" }}>
                  <p style={{ paddingBottom: "10px" }}>Total Liquidity</p>
                  <p>FUSII Token Price</p>
                </div>
                <div
                  className="col-right"
                  style={{
                    fontSize: "18px",
                    color: "#1d4eda",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  <p style={{ paddingBottom: "10px" }}>$0.00</p>
                  <p>$0</p>
                </div>
              </div>
            </div>
          </div>
        </div>
                */}

        {/* Card Section Here... */}
        {state?
        <>
        <div className="card_sec">
          <div className="row">
            <div className="cols">
              <div className="img_cont">
                <img src={PancakeLogo} alt="Pancake Image" />
              </div>
              <div className="col_descd">
                <p >
                LP Staked: <b>{stakedLP}</b> 
                </p>
                <span style={{ color: "#96a5a5" }}>Available: {availLP +" LP"}</span>
              </div>
              <hr />
              <div className="buttons_sec">
                {isApproved?<>
                <div className="row">
                  {toggle?
                  <div className="row"> <a  style={{color:"blue", textAlign:"right", pointer:"cursor"}} onClick={()=>setToggle(false)}>stake</a></div>:
                  <div className="row"> <a  style={{color:"red", textAlign:"right",pointer:"cursor"}} onClick={()=>setToggle(true)}>unstake</a></div>}
                <div  className="row">
                {toggle?<>
                
                <input style={{width:"50%"}} onChange={onInputValue} type="number" placeholder="Amt of LP to UnStake"></input>
                <Button type="submit" onClick={_unstake}>Unstake</Button>
                </>:<>
                
                                <input style={{width:"50%"}} onChange={onInputValue} type="number" placeholder="Amt of LP to Stake"></input>
                              
                                <Button type="submit" onClick={_stake}>Stake</Button>
                              
                    </>}
                
                </div></div>
                </>:<>
                <Button onClick={approveLPToken} type="submit">Approve</Button>
                <Button type="submit" disabled>
                  Stake
                </Button>
                </>}
              </div>
            </div>

            <div className="cols">
              <div className="img_cont">
                <img src={FusiLogo} alt="Fusi Image" />
              </div>
              <div style={{ paddingBottom: "30px" }} className="col_descd">
                <p>
                 Unclaimed Reward: <b>{unclaimed}</b> 
                </p>
              </div>
              <hr />
              <div className="buttons_sec">
                <Button type="submit" onClick={getReward}>Claim Rewards</Button>
              </div>
            </div>
          </div>
        </div>
        </>:<>
        <div className="col center"><h4>Please connect to the Metamask Wallet</h4></div>
        </>}
      </div>
    </div>
  );
};

export default Stake;
