import React, { Component } from "react";

export class Worth extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="form-container">
        <h1 className="mb-5">NFT Worth</h1>
        <div className="form-group">
          <label htmlFor="amount">Base Amount</label>
          <input
            type="number"
            className="form-control"
            name="amount"
            onChange={inputChange("amount")}
            value={values.amount}
          />
        </div>
        <div className="form-group">
          <label htmlFor="token">Number of Token</label>
          <input
            type="number"
            className="form-control"
            name="token"
            onChange={inputChange("token")}
            value={values.token}
          />
        </div>
        <div className="form-group">
          <label htmlFor="nft">NFT Worth</label>
          <input
            type="number"
            className="form-control"
            name="nft"
            onChange={inputChange("nft")}
            value={values.nft}
          />
        </div>

        <br />

        <div className="row">
          <div className="col-6">
            <div className="button-left" onClick={this.back}>
              <i className="fa fa-arrow-left"></i>
              <div></div>
              Previous
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="button" onClick={this.continue}>
              Next
              <div></div>
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Worth;
