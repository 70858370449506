import React, { Component } from "react";

export class Bind extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="form-container">
        <h1 className="mb-5">Bind Price</h1>
        <div className="form-group">
          <label htmlFor="slotid">Slot Id</label>
          <input
            type="text"
            className="form-control"
            name="slotid"
            onChange={inputChange("slotid")}
            value={values.slotid}
          />
        </div>
        <div className="form-group">
          <label htmlFor="number">Price</label>
          <input
            type="number"
            className="form-control"
            name="price"
            onChange={inputChange("price")}
            value={values.price}
          />
        </div>

        <br />

        <div className="row">
          <div className="col-6">
            <div className="button-left" onClick={this.back}>
              <i className="fa fa-arrow-left"></i>
              <div></div>
              Previous
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="button" onClick={this.continue}>
              Next
              <div></div>
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Bind;
