import React from "react";
import "antd/dist/antd.css";
import { Slider, InputNumber, Row, Col } from "antd";

export default class NumSlider extends React.Component {
  state = {
    inputValue: 0,
  };
 
  
  onChange = (value) => {
    this.setState({
      inputValue: value,
    });
    this.props.sValue(value);

  };

  render() {
   
    const { inputValue } = this.state;
    return (
      <Row>
        <Col span={12}>
          <Slider
            min={0}
            step={0.01}
            max={this.props.eMax*2}
            onChange={this.onChange}
            value={typeof inputValue === "number" ? inputValue : 0}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={0}
            step={0.01}
            max={this.props.eMax*2}
            style={{ margin: "0 16px", backgroundColor: "aliceblue" }}
            value={inputValue}
            onChange={this.onChange}
          />
        </Col>
      </Row>
    );
  }
}
