import React, { Component } from "react";

export class Supply extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="form-container">
        <h1 className="mb-5">Confirm You Supply</h1>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={inputChange("name")}
            value={values.name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="symbol">Symbol</label>
          <input
            type="text"
            className="form-control"
            name="symbol"
            onChange={inputChange("symbol")}
            value={values.symbol}
          />
        </div>
        <div className="form-group">
          <label htmlFor="supply">Total Supply</label>
          <input
            type="number"
            className="form-control"
            name="supply"
            onChange={inputChange("supply")}
            value={values.supply}
          />
        </div>

        <br />

        <div className="row">
          <div className="col-6">
            <div className="button-left" onClick={this.back}>
              <i className="fa fa-arrow-left"></i>
              <div></div>
              Previous
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="button" onClick={this.continue}>
              Next
              <div></div>
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Supply;
