import React from "react";
import "./HomeDashboard.css";
import { NavLink } from "react-router-dom";
import VideoPlayer from "react-video-js-player";
import FusiVideo from "../videos/fusiVideo.mp4";
import FusiImage from "../images/image-placeholder-1920.png";

const HomeDashboard = () => {
  const videoSrc = FusiVideo;
  const poster = FusiImage;

  return (
    <div className="hd_container">
      <div className="rrow">
        <div className="colld">
          <h1>Price Discovery Protocol for NFTs</h1>
          <h5>
            Liquefy your NFTs and create pools on Fusible and AMM for a
            curve-based price discovery
          </h5>

          <NavLink
            exact
            to="/stake"
          >
            <button>Stake</button>
          </NavLink>
        </div>
        <div className="colrd">
          <VideoPlayer src={videoSrc} poster={poster} />
        </div>
      </div>
    </div>
  );
};

export default HomeDashboard;
