import React, { useState, useContext } from "react";
import "./Dashboard.css";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Web3Context } from "../Context/Web3context";

const Dashboard = () => {
  const state = useContext(Web3Context);
  const [isInvestor, setIsInvestor] = useState();
  const [invFusii, setFusii] = useState();

  if (state !== undefined) {
    const inv = state.inv;
    const fusii = state.fusii;
    if (isInvestor === undefined) {
      inv.methods
        .investorsInfo(state.address)
        .call({ from: state.address })
        .then((r) => {
          console.log(r);
          let info = {
            exists: r.exists,
            tokensAllotment: parseFloat(
              state.web3.utils.fromWei(r.tokensAllotment.toString(), "ether")
            ),
            withdrawnTokens: parseFloat(
              state.web3.utils.fromWei(r.withdrawnTokens.toString(), "ether")
            ),
          };
          inv.methods
            .withdrawableTokens(state.address)
            .call({ from: state.address })
            .then((e) => {
              info.withdrawableTokens = parseFloat(
                state.web3.utils.fromWei(e.toString(), "ether")
              );
              setIsInvestor(info);
            });
        });
      fusii.methods
        .balanceOf(state.address)
        .call({ from: state.address })
        .then((r) => {
          console.log(r);
          setFusii(parseFloat(state.web3.utils.fromWei(r.toString(), "ether")));
        });
    }
  }

  const withdraw = () => {
    if (state !== undefined) {
      const inv = state.inv;
      inv.methods
        .withdrawTokens()
        .send({ from: state.address })
        .then((r) => {
          console.log(r);
        });
    }
  };
  return (
    <div>
      {state ? (
        <>
          <div className="d_container">
            <h1>Fusible - Investor Dashboard</h1>
            {isInvestor ? (
              <>
                {isInvestor.exists ? (
                  <>
                    <div className="row">
                      <div className="column">
                        <h6 style={{ color: "#a1a4a9" }}>FUSII Allotment</h6>
                        <h5>{isInvestor.tokensAllotment + " FUSII"}</h5>
                      </div>
                      <div className="column">
                        <h6 style={{ color: "#a1a4a9" }}>Withdrawable FUSII</h6>
                        <h5>{isInvestor.withdrawableTokens + " FUSII"}</h5>
                        <br />
                        <Button onClick={withdraw} type="submit">
                          Withdraw FUSII
                        </Button>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="table-responsive col"
                        style={{ marginTop: "-25px" }}
                      >
                        <Table responsive="sm">
                          <thead>
                            <tr>
                              <th colSpan="2">Investor Info</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Address</td>
                              <td>{state.address}</td>
                            </tr>
                            <tr>
                              <td>FUSII in wallet</td>
                              <td>{invFusii + " FUSII"}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <h2>
          <center>Please connect to Metamask web3 wallet</center>
        </h2>
      )}
    </div>
  );
};

export default Dashboard;
