import React, { Component } from "react";
import TokenList from "./TokenList";
import Supply from "./Supply";
import Success from "./Success";
import Worth from "./Worth";
import ConfirmCreate from "./ConfirmCreate";

export default class Form extends Component {
  state = {
    step: 1,
    currency: "",
    name: "",
    symbol: "",
    supply: "",
    amount: "",
    token: "",
    nft: "",
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  inputChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  render() {
    const { step } = this.state;
    const { currency, name, symbol, supply, amount, token, nft } = this.state;
    const values = { currency, name, symbol, supply, amount, token, nft };

    switch (step) {
      case 1:
        return <TokenList nextStep={this.nextStep} values={values} />;
      case 2:
        return (
          <Supply
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
      case 3:
        return (
          <Worth
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
      case 4:
        return (
          <ConfirmCreate
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
      case 5:
        return <Success />;

      default:
        return <TokenList nextStep={this.nextStep} values={values} />;
    }
  }
}
