import React, { useState } from "react";
import ExploreBtn from "./ExploreBtn";
import items from "./exploreData";
import ExploreMenu from "./ExploreMenu";
import ExploreSlider from "./ExploreSlider";
import { SliderData } from "./ExploreSdata";

const allCategories = [
  "All ⚡",
  ...new Set(items.map((item) => item.category)),
];

const Explore = () => {
  const [menuItem, setMenuItem] = useState(items);
  const [buttons, setButtons] = useState(allCategories);

  const filter = (button) => {
    if (button === "All ⚡") {
      setMenuItem(items);
      return;
    }

    const filterData = items.filter((item) => item.category === button);
    setMenuItem(filterData);
  };

  return (
    <div className="ex_app">
      {/* <div className="ex_title">
        <h1>
          Explore
          <span className="ex_s">Fusi ⚡</span>
        </h1>
      </div> */}
      <ExploreSlider slides={SliderData} />
      <ExploreBtn button={buttons} filter={filter} />
      <ExploreMenu menuItem={menuItem} />
    </div>
  );
};

export default Explore;
