import React, { Component } from "react";

export class AccountSetup extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="form-container">
        <h1 className="mb-5">Social Profile</h1>
        <div className="form-group">
          <label htmlFor="userid" className="model_ht">
            Twitter Username
          </label>
          <input
            type="text"
            className="form-control"
            name="userid"
            onChange={inputChange("userid")}
            value={values.userid}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Retweet Post URL</label>
          <input
            type="url"
            className="form-control"
            name="url"
            onChange={inputChange("url")}
            value={values.url}
          />
        </div>

        <br />

        <div className="text-right">
          <div class="button" onClick={this.continue}>
            Next
            <div></div>
            <i class="fa fa-arrow-right"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountSetup;
