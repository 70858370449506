import React, { Component } from "react";

export class Confirm extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values: { name, email, slotid, price, userid, url },
    } = this.props;

    return (
      <div className="form-container">
        <h1 className="mb-5">Confirm</h1>
        <ul class="list-group">
          <li class="list-group-item">Twitter Userid : {userid}</li>
          <li class="list-group-item">
            Twitter URL : <a href={url}>{url}</a>
          </li>
          <li class="list-group-item">Name : {name}</li>
          <li class="list-group-item">Email : {email}</li>
          <li class="list-group-item">Slotid : {slotid}</li>
          <li class="list-group-item">Price : {price}</li>
        </ul>

        <br />
        <br />

        <div className="row">
          <div className="col-6">
            <div className="button-left" onClick={this.back}>
              <i className="fa fa-arrow-left"></i>
              <div></div>
              Previous
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="button" onClick={this.continue}>
              Next
              <div></div>
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Confirm;
