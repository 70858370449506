import React, { Component } from "react";

export class Confirm extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values: { currency, name, symbol, supply, amount, token, nft },
    } = this.props;

    return (
      <div className="form-container">
        <h1 className="mb-5">Confirm</h1>
        <ul class="list-group">
          <li class="list-group-item">Currency : {currency}</li>
          <li class="list-group-item">Name : {name}</li>
          <li class="list-group-item">Symbol : {symbol}</li>
          <li class="list-group-item">Supply : {supply}</li>
          <li class="list-group-item">Price : {amount}</li>
          <li class="list-group-item">Token : {token}</li>
          <li class="list-group-item">NFT Value : {nft}</li>
        </ul>

        <br />
        <br />

        <div className="row">
          <div className="col-6">
            <div className="button-left" onClick={this.back}>
              <i className="fa fa-arrow-left"></i>
              <div></div>
              Previous
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="button" onClick={this.continue}>
              Next
              <div></div>
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Confirm;
