import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import Explore from "./components/Explore";
import Navbar from "./components/Navbar";
import Web3 from "web3";
import Web3Modal from "web3modal";
import Profile from "./components/Profile";
import Onsale from "./components/Onsale";
import Nfts from "./components/Nfts";
import Myfts from "./components/Myfts";
import { Web3Context } from "./Context/Web3context";
import MintNFT from "./components/MintNFT";
import Dashboard from "./components/Dashboard";
import HomeDashboard from "./components/HomeDashboard"
import Stake from "./components/Stake";
import {FusiiToken,InvestorCon,StakeV0 ,StakeV2 } from "./Contract/constant";
import {invABI,Ierc20,stakeV0ABI} from "./Contract/abi/abi";

function App() {
  const [state, setState] = useState();

  async function init() {
    console.log("starting up");
    //console.log("WalletConnectProvider is", WalletConnectProvider);
    //console.log("Fortmatic is", Fortmatic);

    const providerOptions = {
      metamask: {},
    };

    const web3Modal = new Web3Modal({
      //network: "mainnet", // optional
      cacheProvider: false, // optional
      //disableInjectedProvider: false,
      providerOptions,
      theme: "dark", // required
    });

    const provider = await web3Modal.connect();
    //console.log(provider);

    const web3 = new Web3(provider);
    //console.log(modal)

    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    if (networkId !== 56) {
      alert("Switch to bsc network");
      web3Modal.clearCachedProvider();
      web3Modal.resetState();
      return;
    }
    const balance = await web3.eth.getBalance(address);
    console.log(address);
    console.log(balance);
    //  console.log(networkId)
    const inv = new web3.eth.Contract(invABI, InvestorCon);
    const fusii = new web3.eth.Contract(Ierc20,FusiiToken);
    const stkv0 = new web3.eth.Contract(stakeV0ABI,StakeV2);
    setState({
      web3,
      provider,
      connected: true,
      address,
      web3Modal,
      networkId,
      inv,
      fusii,
      stkv0
    });
    // window.provider = provider;
    await subscribeProvider(provider);
  }

  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      return;
    }
    // provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts) => {
      //if(state !== undefined && state.web3 !== undefined)
      //  console.log(state);  
      //setProviderData({ address: accounts[0] });
      //setState({ address: accounts[0] });
      init();
    });
    provider.on("chainChanged", async (chainId) => {
     // const { web3 } = this.state;
      //const networkId = await web3.eth.net.getId();
     // setState({ chainId, networkId });
     //setProviderData({ chainId, networkId });
     init();
    });
    provider.on("networkChanged", async (networkId) => {
      //const { web3 } = this.state;
      //const chainId = await web3.eth.chainId();
    //  await this.setState({ chainId, networkId });
    //setProviderData({ chainId, networkId });
    init();
    });
    provider.on("open", () => {
      console.log("open");
    });

    // Subscribe to session disconnection/close
    provider.on("close", (code, reason) => {
      console.log(code, reason);
    });
  };

  const onConnect = async () => {
    try {
      await init();
    } catch (err) {
      console.log(err);
      alert("failed to connect");
    }
  };

  const logout = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  return (
    <div>
      <Web3Context.Provider value={state}>
        <Navbar onConnect={onConnect} logout={logout} />
        {/* <Header
        web3State={state ? state : false}
        onConnect={onConnect}
        logout={logout}
      /> */}
        <Switch>
          {/* This Route redirect to Home Page */}
          <Route exact path="/" component={HomeDashboard}/>
          <Route exact path="/investor" component={Dashboard} />
          <Route exact path="/stake" component={Stake} />
          {/*<Route exact path="/" component={Explore} />
          <Route exact path="/mintnft" component={MintNFT} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/profile/onsale" component={Onsale} />
          <Route exact path="/profile/mynfts" component={Nfts} />
          <Route exact path="/profile/myfts" component={Myfts} />
           This line help us to deal with wrong route link */}

          <Redirect to="/" />
        </Switch>
      </Web3Context.Provider>
    </div>
  );
}

export default App;
