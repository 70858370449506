// import svg1 from "../images/1 - REBUILD THE ECONOMY.svg";
// import svg2 from "../images/2 - INCOME INEQUALITY.svg";
// import svg3 from "../images/3 - JOB LOOKING.svg";
// import svg4 from "../images/4 - BUDGETTING.svg";
// import svg5 from "../images/5 - RECRUITING.svg";
// import svg6 from "../images/6 - FINANCES.svg";
// import svg7 from "../images/7 - BANK DEAL.svg";
// import svg8 from "../images/8 - BUYING A HOUSE.svg";
// import svg9 from "../images/9 - ECONOMY ANALYSIS.svg";
// import svg10 from "../images/10 - BIKE .svg";

export default [
  {
    id: 0,
    image: "https://media.giphy.com/media/l0Hly6YhCTc1rzP56/giphy.gif",
    title: "REBUILD ECO",
    category: "ECO",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
  {
    id: 1,
    image: "https://media.giphy.com/media/xThuW7WFLXB9cpVnzy/giphy.gif",
    title: "INCOME",
    category: "INC",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
  {
    id: 2,
    image: "https://media.giphy.com/media/3oEjHJMK0PaGinZGPS/giphy.gif",
    title: "LOOKING",
    category: "JBL",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
  {
    id: 3,
    image: "https://media.giphy.com/media/xT0xeDXoIqj2JtVI3K/giphy.gif",
    title: "BUDGETTING",
    category: "BUD",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
  {
    id: 4,
    image: "https://media.giphy.com/media/Bpazs9pI5X4IXhClD5/giphy.gif",
    title: "RECRUITING",
    category: "REC",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
  {
    id: 5,
    image: "https://media.giphy.com/media/xT8qBp5xsbxYZlbwvC/giphy.gif",
    title: "FINANCES",
    category: "ECO",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
  {
    id: 6,
    image: "https://media.giphy.com/media/xT0GqGyS81lrEHvzeo/giphy.gif",
    title: "BANK DEAL",
    category: "REC",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
  {
    id: 7,
    image: "https://media.giphy.com/media/l3vR5kNG0wixNLhEk/giphy.gif",
    title: "BUYING A",
    category: "INC",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
  {
    id: 8,
    image: "https://media.giphy.com/media/l3fQ93E7oBibvLXVK/giphy.gif",
    title: "ECONOMY",
    category: "ECO",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
  {
    id: 9,
    image: "https://media.giphy.com/media/1yTeIHGmcinKAD67kz/giphy.gif",
    title: "BIKE",
    category: "JBL",
    price: "0.94 ETH ",
    available: " 1 of 1",
    description: "Place a bid",
  },
];
