import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Form } from "react-bootstrap";

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ich",
    },
  },
}));

export default function TokenList(props) {
  const classes = useStyles();
  const [currency, setCurrency] = React.useState("EUR");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <Form.Group
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
        >
          <Form.Label style={{ fontSize: "32px" }}>
            NFT contract address:
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="NFT contract address"
            autosuggestion="off"
          />
          <Form.Label style={{ fontSize: "32px" }}>Token id: </Form.Label>
          <Form.Control type="text" placeholder="Token id" />
        </Form.Group>
      </div>

      <br />

      <div className="text-right">
        <div
          className="button"
          onClick={(e) => {
            e.preventDefault();
            props.nextStep();
          }}
        >
          Next
          <div></div>
          <i className="fa fa-arrow-right"></i>
        </div>
      </div>
    </form>
  );
}
