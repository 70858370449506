import React, { Component } from "react";

export class Success extends Component {
  render() {
    return (
      <div>
        <h1 className="text-black">Bind Placed Successfully! 🥳</h1>
      </div>
    );
  }
}

export default Success;
